@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
      margin: 0;
      font-family: -apple-system, BlinkMacSystemFont, "Azeret Mono", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
          "Droid Sans", "Helvetica Neue", sans-serif;
  }
}
